<template>
  <div class="card">
    <div class="card-header py-3">
      <div class="d-flex justify-content-between align-items-center">
        <span class="font-weight-medium">Riwayat Barang</span>
        <v-btn icon class="" @click="handleClose">
          <i class="fa-solid fa-xmark"></i>
        </v-btn>
      </div>
    </div>
    <div class="card-body pr-10">
      <div>
        <p class="fw-bold h5">{{ report.uraian }}</p>
        <p class="fw-medium fs-14">
          Sisa Kuota : {{ +report.kuota - +report.volume }}
          <br />
          Sisa Kuota Terkecil :
          {{ +report.kuota_terkecil - +report.volume_terkecil }}
        </p>

        <div class="row">
          <div class="col-md-6">
            <table class="mb-3 fs-13 lh-lg">
              <tr
                v-for="(item, i) in [
                  {
                    key: 'No. Surat Permohonan',
                    value: report.no_surat_mohon,
                  },
                  {
                    key: 'Seri',
                    value: report.seri,
                  },
                  {
                    key: 'Hs Code',
                    value: report.hs,
                  },
                  {
                    key: 'Negara Asal',
                    value: report.negara_asal,
                  },
                  {
                    key: 'Pelabuhan Tujuan',
                    value: report.pelabuhan_tujuan,
                  },
                ]"
                :key="i"
              >
                <td style="max-width: 180px">{{ item.key }}</td>
                <td style="min-width: 20px">:</td>
                <td>{{ item.value }}</td>
              </tr>
            </table>
          </div>
          <div class="col-md-6">
            <table class="mb-3 fs-13 lh-lg">
              <tr
                v-for="(item, i) in [
                  {
                    key: 'Kuota',
                    value: `${report.kuota} (${report.satuan})`,
                  },
                  {
                    key: 'Kuota Terkecil',
                    value: `${report.kuota_terkecil} (${report.satuan_terkecil})`,
                  },
                  {
                    key: 'Volume Realisasi',
                    value: `${report.volume} (${report.satuan})`,
                  },
                  {
                    key: 'Volume Realisasi (Terkecil)',
                    value: `${report.volume_terkecil} (${report.satuan_terkecil})`,
                  },
                  {
                    key: 'Persentase Realisasi',
                    value: report.persentase + '%',
                  },
                  {
                    key: 'Persentase Realisasi (Terkecil)',
                    value: report.persentase_terkecil + '%',
                  },
                ]"
                :key="i"
              >
                <td style="max-width: 180px">
                  {{ item.key }}
                </td>
                <td style="min-width: 20px">:</td>
                <td class="fw-medium">{{ item.value }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <br />
      <v-timeline dense clipped>
        <v-timeline-item
          class="mb-4"
          color="green lighten-2"
          icon-color="grey lighten-2"
          small
          fill-dot
          v-for="(item, i) in report.history"
          :key="i"
        >
          <div class="row">
            <div class="col-12 col-sm-6 mb-5">
              <p class="fw-bold text-muted mb-0">
                <!-- {{ item.type_history }} -->
                {{ report.idlayan == "009003" ? "Pemasukan" : "Pengeluaran" }}
              </p>
              <a
                :href="linkDownload(item.upload_id)"
                :download="item.docs_number + '.pdf'"
                class="small"
              >
                <i class="fa-solid fa-download"></i>
                Dokumen PIB
              </a>
            </div>
            <div class="col-12 col-sm-6 text-sm-end small">
              <p class="mb-2">
                <span class="fw-medium">{{ formatDate(item.created_at) }}</span>
              </p>
              <p class="fs-14">
                Nilai Sesuai PIB :
                <span class="fw-medium">
                  {{ item.nilai }} ({{ item.kurs_pib }})
                </span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4">
              <label class="fs-12 fw-medium text-muted mb-3">
                Volume Sebelum
              </label>
              <v-text-field
                outlined
                dense
                class="rounded-pill"
                disabled
                :value="item.kuota"
                :suffix="item.satuan"
              />
            </div>
            <div class="col-12 col-sm-4">
              <label class="fs-12 fw-medium text-muted mb-3">
                Volume Realisasi
              </label>
              <v-text-field
                outlined
                dense
                class="rounded-pill"
                disabled
                :value="item.volume"
                :suffix="item.satuan"
              />
            </div>
            <div class="col-12 col-sm-4">
              <label class="fs-12 fw-medium text-muted mb-3">
                Volume Sesudah
              </label>
              <v-text-field
                outlined
                dense
                class="rounded-pill"
                disabled
                :value="item.sisa_kuota"
                :suffix="item.satuan"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4">
              <label class="fs-12 fw-medium text-muted mb-3">
                Volume Sebelum <br />
                Dalam Satuan Tekecil
              </label>
              <v-text-field
                outlined
                dense
                class="rounded-pill"
                disabled
                :value="item.kuota_terkecil"
                :suffix="item.satuan_terkecil"
              />
            </div>
            <div class="col-12 col-sm-4">
              <label class="fs-12 fw-medium text-muted mb-3">
                Volume Realisasi <br />
                Dalam Satuan Tekecil
              </label>
              <v-text-field
                outlined
                dense
                class="rounded-pill"
                disabled
                :value="item.volume_terkecil"
                :suffix="item.satuan_terkecil"
              />
            </div>
            <div class="col-12 col-sm-4">
              <label class="fs-12 fw-medium text-muted mb-3">
                Volume Sesudah <br />
                Dalam Satuan Terkecil
              </label>
              <v-text-field
                outlined
                dense
                class="rounded-pill"
                disabled
                :value="item.sisa_kuota_terkecil"
                :suffix="item.satuan_terkecil"
              />
            </div>
          </div>
        </v-timeline-item>
      </v-timeline>
    </div>
  </div>
</template>

<script>
import format3Digit from "../../utils/format-3digit";
import moment from "moment";

export default {
  name: "BarangHistory",
  data() {
    return {
      format3Digit,
      formatDate: (date) => moment(date).format("DD MMMM YYYY"),
      baseUrl: "https://api.batam-itinventory.com",
      linkDownload: (upload_id) => `${this.baseUrl}/documents/${upload_id}.pdf`,
    };
  },
  computed: {
    report() {
      return this.$store.state.barang.report;
    },
  },
  methods: {
    handleClose() {
      this.$emit("handleModalHistory");
    },
  },
};
</script>
